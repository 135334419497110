/* eslint-env browser */
import React from "react";
import {DateTime} from "luxon";
import PropTypes from "prop-types";

import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import {useInterval, useLocalStorage} from "@tzmedical/react-hooks";

import axios from "../../axiosClient.js";
import useEnvironmentVariables from "../hooks/useEnvironmentVariables.jsx";

const REFRESH_INTERVAL_MS = 30000;
const MAX_RANDOM_MS = 10000;
const DEFAULT_COUNT_VALUE = "-";

// This function runs when reading values from localStorage or when a new value is set
// but this component to ensure that it is valid.
const validateCount = (countObject) => {
  try {
    const {count, timeExpired} = countObject;
    // If either count or timeExpired is undefined, etc. then we need to reset to the default value
    if ([null, undefined, false].includes(count) || [null, undefined, false].includes(timeExpired)) {
      return false;
    }

    // If the value is out-of-date then we need to reset to the default value
    if (count !== DEFAULT_COUNT_VALUE && DateTime.fromMillis(timeExpired) < DateTime.now()) {
      return false;
    }

    // These values are valid-non-number options
    if (count === DEFAULT_COUNT_VALUE || count === "99+") {
      return true;
    }

    // Otherwise, we should have a number between 0 and 99
    if (Number(count) >= 0 && Number(count) <= 99) {
      return true;
    }
  } catch (err) {
    return false;
  }

  return false;
};

// This function returns the time in milliseconds when the new count value should be considered expired
const getExpiration = () => DateTime.now().plus({milliseconds: REFRESH_INTERVAL_MS}).toMillis();

function SideBarChip({
  // Props
  color,
  url,
  params,
  tooltip,
  id,
  clickSearch,
  clickPage,
}) {
  const {apiUrl} = useEnvironmentVariables();
  const [loading, setLoading] = React.useState(true);
  const [{count, timeExpired}, setCount] = useLocalStorage(
    id,
    {count: DEFAULT_COUNT_VALUE, timeExpired: getExpiration()},
    validateCount
  );

  const getCount = React.useCallback(async () => {
    if (url) {
      try {
        const {headers} = await axios({
          method: "GET",
          url: `${apiUrl}${url}`,
          params,
          headers: {"tzmedical-br-origin": "side-bar-chip"},
        });
        if (Number(headers.count) > 99) {
          setCount({count: "99+", timeExpired: getExpiration()});
        } else {
          setCount({count: headers.count, timeExpired: getExpiration()});
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        setCount({count: DEFAULT_COUNT_VALUE, timeExpired: DateTime.now().minus({seconds: 1}).toMillis()});
      }
    }
  }, [apiUrl, params, setCount, url]);

  // Pick a random amount of time to add between each refresh to avoid every tab hitting at the same moment
  const randomizedInterval = React.useMemo(
    () => Math.floor(Math.random() * MAX_RANDOM_MS) + REFRESH_INTERVAL_MS,
    // The following is what makes the useInterval reset every time local storage is updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timeExpired]
  );
  useInterval(getCount, randomizedInterval, loading || count === DEFAULT_COUNT_VALUE);

  const handleClick = React.useCallback(() => {
    window.localStorage.setItem(
      "search",
      JSON.stringify({
        text: clickSearch,
        timeExpired: DateTime.now().plus({seconds: 30}).toMillis(),
      })
    );

    window.open(`/${clickPage}`, "_self");
  }, [clickPage, clickSearch]);

  return (
    <Tooltip placement="bottom" title={tooltip} arrow>
      <Chip
        size="small"
        color={color}
        id={id}
        data-cy={id}
        onClick={clickPage && handleClick}
        label={
          <Typography fontSize="11px" fontWeight="500" color="white">
            {count}
          </Typography>
        }
        sx={{ml: 0.3, width: "34px", height: "18px"}}
      />
    </Tooltip>
  );
}

SideBarChip.propTypes = {
  // The color to use for the chip
  color: PropTypes.string.isRequired,

  // The URL to hit to get a count
  url: PropTypes.string.isRequired,

  // Query params to include with the request
  params: PropTypes.object,

  // Tooltip to display
  tooltip: PropTypes.string,

  // An id to use for cross-tab syncing
  id: PropTypes.string.isRequired,

  clickSearch: PropTypes.string,
  clickPage: PropTypes.string,
};

export default SideBarChip;
